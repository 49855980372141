'use client'

import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
//import { useCookies } from 'react-cookie';

export default function ModalNewsletter(props:any) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    //const [cookies, setCookie, removeCookie] = useCookies(["newsletter"]);

    const onClick = () => {
        const script = document.createElement("script");
        script.src = "https://app.getresponse.com/view_webform_v2.js?u=QOEc8&webforms_id=z9lez";
        script.setAttribute("data-webform-id", "z9lez");
        script.async = false;
        document.body.appendChild(script);

        setModalOpen(true);
    }

    //console.log(cookies.newsletter);
    //setCookie('newsletter', 1);

    return (
        <>
        <div className="relative cursor-pointer" onClick={ onClick } aria-label="">
            { props.children }
        </div>
        <Transition show={ modalOpen } as={ Fragment }>
            <Dialog onClose={() => setModalOpen(false)}>
                {/* Modal backdrop */}
                <Transition.Child
                    className="fixed inset-0 z-[99999] bg-black bg-opacity-50 transition-opacity"
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-out duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    aria-hidden="true"
                />
                {/* End: Modal backdrop */}

                {/* Modal dialog */}
                <Transition.Child
                    className="fixed inset-0 z-[99999] flex px-4 md:px-6 py-6"
                    enter="transition ease-out duration-300"
                    enterFrom="opacity-0 scale-75"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-out duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-75"
                >
                <div className="max-w-full md:max-w-2xl mx-auto h-full flex items-center">
                    <Dialog.Panel className="w-full max-h-full shadow-2xl aspect-video bg-white overflow-hidden" style={{minHeight:'440px'}}>
                        <script type="text/javascript" src="https://app.getresponse.com/view_webform_v2.js?u=QOEc8&webforms_id=z9lez" data-webform-id="z9lez"></script>
                    </Dialog.Panel>
                </div>
                </Transition.Child>
                {/* End: Modal dialog */}
            </Dialog>
        </Transition>
        </>
    )
}